import { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import authSelector from '@Redux/auth/authSelector';

import { transformAudioListResults } from '@/utils/transformData/transformResults';
import { getData as fetchData, deleteData, deleteDataNew, postData } from '@/utils/api';
import useRunOnFirstMount from "./useRunOnFirstMount";


export default function useFetchData({params: defaultParams, categories, useDefaultParams = false, usePagination = true, newFunctions = false}) {
	const [searchParams, setSearchParams] = useSearchParams();
	const { jwttoken } = useSelector(authSelector);
	const [data, setData] = useState(null);
	const [ pagination, setPagination] = useState({
		'page' : 1,
		'pageSize' : 20,
		'totalElements' : 0
	});
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useRunOnFirstMount(() => {
		if (jwttoken && !defaultParams) {
			getData();
		}
	});

	useEffect(() => {
		if (useDefaultParams) {
      getData();
    }
	}, [defaultParams, useDefaultParams]);

	const getData = async () => {
		let params = {type: 'audio', pageSize: 20, page: 1, sort: 'mediaDate:desc', searchType: 'search', search: ''};
		params = {...params, ...defaultParams};

		if (!useDefaultParams) {
      for(const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
    } else {
			params = defaultParams;
		}

		if (!usePagination) {
			delete params.page;
      delete params.pageSize;
		}

		const { url, type } = params;

		try {
			setLoading(true);

			const response = await fetchData({auth: jwttoken, endpoint: `/${url || type}?${createUrlParams(params)}`});

			if (response.error) {
				reject(response);
				return;
			}

			const res = transformAudioListResults(response.content);

			setResults(res);

		} catch (err) {
			reject(err);
			return;
		}
	};

	const deleteItem = async (epId, url = defaultParams.url || defaultParams.type) => {
			try {
				setLoading(true);

				const func = newFunctions ? deleteDataNew : deleteData;

				const response = await func({auth: jwttoken, endpoint: `/${url}/${epId}`});

				if (response.error) {
					reject(response, data);
					return;
				}

				getData();

			} catch (err) {
				reject(err, data);
				return;
			}
	};

	const toggleSelect = async ({id, select = true, url = defaultParams.url || defaultParams.type}) => {
		try {
			setLoading(true);
			const func = select ? postData : deleteData;

			const response = await func({auth: jwttoken, endpoint: `/${url}/${id}`});

			if (response.error) {
				reject(response, data);
				return;
			}

		} catch (err) {
			reject(err, data);
			return;
		}
	};

	const toggleSelectNew = async ({select = true, url}) => {
		try {
			setLoading(true);
			const func = select ? postData : deleteDataNew;

			const response = await func({auth: jwttoken, endpoint: url, noResponse: true});

			setLoading(false);

			if (response.error) {
				reject(response, data);
				return;
			}

			getData();

		} catch (err) {
			reject(err, data);
			return;
		}
	};

	const reject = (err, data = null) => {
		setError(err);
		setData(data);
		setLoading(false);
	};

	const setResults = (res) => {
		setError(null);
		setData(res.data);
		setPagination(res.pagination);
		setLoading(false);
	};

	const setParams = (params, loadData = true) => {
		setSearchParams(parameters => {
			Object.entries(params).forEach(([key, value]) => {
				if (!value || value.length === 0) {
					deleteParam(key, loadData);
					return;
				}

				parameters.set(key, value);
			});
			return parameters;
		});

		if (loadData) {
			getData();
		}
	};

	const setParam = (name, loadData = true) => {
		return (param) => {
			const value = param?.target ? (param?.target?.value || '') : param;

			setParams({[name]: value}, loadData);
		};
	};

	const setDefaultParams = (params, loadData = true) => {
		Object.entries(params).forEach(([key, value]) => {
      defaultParams[key] = value;
    });

		if (loadData) {
			getData();
		}
	};

	const deleteParam = (param, loadData = true) => {
		searchParams.delete(param);

		if (loadData) {
			getData();
		}
	};

	function createUrlParams (params) {
		let urlParams = '';
		const { category } = params;

		Object.entries(params).forEach(([key, value]) => {
			if (!['search','searchType','type', 'url', 'category'].includes(key) && value !== '') {
				if (urlParams !== '') {
					urlParams += '&';
				}
				urlParams += key + '=' + value;
			}
		});

		if(params['search'] && params['searchType']) {
			urlParams += '&' + params['searchType'] + '=' + params['search'];
		}

		if(category) {
			category.split(',').forEach(item => {
				const cat = categories[item];

				if (typeof cat === "object" && cat.length > 0) {
					cat.forEach((id) => {
						urlParams += '&category=' + id;
					});
				} else if (cat) {
					urlParams += '&category=' + cat;
				}
			});
    }

		return urlParams;
	}


	return {setParams, setParam, searchParams, getData, deleteParam, data, pagination, error, loading, deleteItem, toggleSelect, setDefaultParams, toggleSelectNew};

}