import { useEffect, useState } from 'react';

import { Input, Pagination, Row, Select, Space } from 'antd';
const { Search } = Input;

import useNotification from '@/hooks/notifications';
import useFetchData from '../hooks/useFetchData';
import TagsTable from '../components/Tables/TagsTable';

const searchTypes = [
	{ label: 'Search by reference ID', value: 'id' },
	{ label: 'Search by keywords', value: 'search' },
];

const categories = [
	{ label: 'Tags', value: "tags" },
	{ label: 'People', value: 'people' },
	{ label: 'Location', value: 'location' },
];

const categoriesToIds = {
  tags: [801, 802, 804, 805],
  people: '806',
  location: '803',
};

export default function Tags() {
	const {openNotification} = useNotification();
	const [searchType, setSearchType] =useState('search');

	const params = {type: 'tags', searchType: 'search'};
	const {
		setParam,
		setParams,
		searchParams,
		error,
		data,
		pagination,
		getData,
		loading
	} = useFetchData({params, newFunctions: true, categories: categoriesToIds});

	useEffect(() => {
		setParams({sort: 'mediaDate:desc'});
	}, []);

	useEffect(() => {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
		}
	}, [error]);

	const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<h1>{'Tags'}</h1>
			<Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchType}
						onChange={setSearchType}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>
			<>
				<TagsTable
					data={data}
					loading={loading}
					categories={categories}
					category={searchParams.get("category")|| ''}
					setCategory={setParam('category')}
					getListData={getData}
				/>
				<Row justify="center">
					<Pagination
						total={pagination.totalElements}
						pageSize={pagination.pageSize}
						current={pagination.page}
						showSizeChanger
						showQuickJumper
						hideOnSinglePage
						disabled={loading}
						onChange={handlePagination}
					/>
				</Row>
			</>
		</Space>
	);
}