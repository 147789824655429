import { shortLanguages } from "./languages";

const notification = {message: 'Something failed!', description: 'The file name is wrong!', type: 'error'};

export function checkAudioNames ({id, openNotification}) {
	return (file) => {
		const name = removeExtension(file.name);
		if( !checkAudioFormat(name, id) ) {
			openNotification(notification);
			return false;
		}
		const [lang, audio] = getParamsBetweenBrackets(name);

		if (!langExist(lang)) {
			openNotification(notification);
			return false;
		}

		if (audio !== 'audio') {
			openNotification(notification);
			return false;
		}

		return true;
	};
}

export function checkThumbnailsNames ({id, openNotification}) {
	return (file) => {
		const name = removeExtension(file.name);
		if( !checkThumbnailFormat(name, id) ) {
			openNotification(notification);
			return false;
		}

		return true;
	};
}

export function checkInfograficNames ({id, openNotification}) {
	return (file) => {
        const name = removeExtension(file.name);
		if( !checkInfographicFormat(name, id) ) {
			openNotification(notification);
			return false;
		}
		const [lang, infographics] = getParamsBetweenBrackets(name);

		if (!langExist(lang)) {
			openNotification(notification);
			return false;
		}

		if (infographics !== 'infographics') {
			openNotification(notification);
			return false;
		}

		return true;
    };
}

export function nameIsEpid ({id, openNotification}) {
	return (file) => {
        const name = removeExtension(file.name);
		if( name !== id ) {
			openNotification(notification);
			return false;
		}

		return true;
    };
}


const removeExtension = (name) => {
	return name.split('.')[0];
};

const checkAudioFormat = (name, id) => {
	if (!id || id === '' ) {
		return false;
	}
	const regex = new RegExp(id + '\\[[^\\]]*\\]', 'i');
	return regex.test(name);
};

const checkInfographicFormat = (name, id) => {
	if (!id || id === '' ) {
		return false;
	}
	const regex = new RegExp(id + '_\\d\\d\\d\\[[^\\]]*\\]', 'i');
	return regex.test(name);
};

const checkThumbnailFormat = (name, id) => {
	if (!id || id === '' ) {
		return false;
	}
	
	const parts = name.split('_');

	const thumbnail = parts.pop();
	const lang = parts.pop();
	const thisId = parts.join('_');

	if ( thisId != id || !langExist(lang) || thumbnail !== 'thumbnail') {
		return false;
	}

	return true;
	
};

export const getParamsBetweenBrackets = (name) => {
	return name.match(/\[([^)]+)]*\]/)?.[1].split('-') || [];
};

const langExist = (lang) => {
	if (shortLanguages.includes(lang.toLowerCase())) {
		return true;
	}
	
	return false;
};

export const getLang = (name) => {
	const params = getParamsBetweenBrackets(name);

	if (params.length > 0) {
		return params[0];
	}

	const parts = name.split('_');

	return parts[parts.length - 2];
};

export function isImageFile(str) {
    // Regex to check valid
    // Image File
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/);
 
    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }
 
    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return "true";
    }
    else {
        return "false";
    }
}

export function getCategoryName(string) {
	//urn:justskos:ep:scheme:{value}

	const value = string.replace('urn:justskos:ep:scheme:', '');
	const name = value.replace('_', ' ');

	return name;
}
