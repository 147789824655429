import { getCategoryName } from "../names";

export function transformAudioListResults (data) {
	const {
		mediaDatas,
		eventMediaDatas,
		topicDatas,
		topicMediaDatas,
		electionsTopicDatas,
		electionsEventDatas,
		eventTopicDatas,
		page,
		pageSize,
		totalElements,
		tagDatas
	} = data;

	let mData = mediaDatas
	|| eventMediaDatas
	|| topicDatas
	|| topicMediaDatas
	|| eventTopicDatas
	|| electionsTopicDatas
	|| electionsEventDatas
	|| tagDatas
	|| [];

	return {
		data: mData.map(media => {
			return {
				key: media?.definitions?.epId || media?.mediaId  || media?.MediaId || media?.epId || media?.topicKey,
				id: media?.id || null,
				epId: media?.definitions?.epId || media?.epId || media?.topicKey || media?.MediaId || '',
				title: media?.title?.['en_GB'] || media?.title || media?.Title,
				eventDate: media?.dates?.eventDate || media?.eventDate ||  media?.MediaDate ||'',
				modifiedDate: media?.dates?.modifiedDate || media?.modified || '',
				eventEndDate: media?.dates?.eventEndDate || null,
				updateDate: media?.updateDate || null,
				mediaId: media?.mediaId || media?.MediaId,
				position: media?.position,
				eventId: media?.eventId,
				selected: media?.Detached || media.Highlighted || false,
				category: media?.category ? getCategoryName(media?.category) : null,
			};
		}),
		pagination: {
			page,
			pageSize,
			totalElements
		}
	};
}