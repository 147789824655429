import { Badge, Button, Popover, Select, Space, Table } from 'antd';
import Icon, { FilterOutlined } from '@ant-design/icons';

import ExternalLink from '@Components/icons/ExternalLink';

import { localTimeFormated } from '@/utils/dates';
import { getPreview } from '@/utils/settings';
import { useMemo } from 'react';

const Filter = ({categories, shownCategories, setCategory}) => (
	<Select
		options={categories}
		value={shownCategories}
		onChange={setCategory}
		size='Large'
		style={{minWidth: 100, width: '100%'}}
		mode="multiple"
	/>
);

export default function TagsTable ({data, loading, category, setCategory, categories}) {
	const shownCategories = useMemo(() => {
		if (!category) return [];

		return category.split(',');
	}, [category]);
	const columns = [
		{
			title: 'Tag ID',
			dataIndex: 'id',
			key: 'id',
			width: 200,
		},
		{
			title: 'Tag name',
			dataIndex: 'title',
			key: 'title',
			style: { wordWrap: 'break-word', wordBreak: 'break-word' }
		},
		{
			title: <Space>
				<span>Category</span>
				<Popover
					content={<Filter setCategory={setCategory} categories={categories} shownCategories={shownCategories}/>}
					title="Filter by category:"
					trigger="click"
					placement="bottom"
				>
					<Badge count={shownCategories.length} color="blue" size="small">
						<FilterOutlined style={{fontSize: 20}}/>
					</Badge>
				</Popover>
			</Space>,
			dataIndex: 'category',
			key: 'category',
			width: 200,
		},
		{
			title: 'Last Update',
			dataIndex: 'modifiedDate',
			key: 'modifiedDate',
			render: (_, item) => {
				return localTimeFormated(item.modifiedDate);
			},
			width: 150,
		},
		{
			key: 'action',
			width: 50,
			render: (_, element) => (
				<Space size="middle">
					<Button
						href={getPreview(element.category === 'Personalities' ? 'person' : 'tag', element.id)}
						target='_blank'
						style={{paddingInline: 5}}
					>
						<Icon component={ExternalLink} color='black'  />
					</Button>
				</Space>
			),
		},
	];

	return (
		<Table
			dataSource={data}
			columns={columns}
			loading={loading}
			pagination={false}
			rowKey="key"
		/>
	);
}